import React, { Component } from 'react';
import { LangConsumer } from 'utils/LangProvider';
import './AttrSetting.less';
import { Button, Tooltip } from 'antd';
import { Icon, LabelSelect } from 'libs';
import _ from 'lodash';
import plugin from 'plugin';
import { setItem } from 'utils/localStorage';
const modeArr = ['portrait', 'behavior'];
class AttrSetting extends Component {
    constructor (props) {
        super(props);
        this.ComponentName = 'AttrSetting';
        //只显示有能力的属性值
        let {$t} = props;
        this.state = {
            attrMap: this.filterAttrMap(props.attrMap, props.attrType),
            detcetMode: props.portraitEnable ? 1 : 0, //默认为普通模式
            options: [{
                value: 0,
                text: $t('ivs.NormalMode'),
            }, {
                value: 1,
                text: $t('ivs.CompliantMode'),
            }],
        };
    }
    componentDidMount () {
        // console.log('attrModalMounted');
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.viewMode !== this.props.viewMode || nextProps.portraitEnable !== this.props.portraitEnable) {
            if (nextProps.viewMode === 'portrait') {
                let mode = nextProps.portraitEnable ? 1 : 0;
                this.setState({detcetMode: mode});
            }
        }
    }
    filterAttrMap = (am, attrType) => {
        let tempAttrMap = _.cloneDeep(am);
        Object.keys(tempAttrMap[attrType]).forEach((key) => {
            let newAttr = {};
            Object.keys(tempAttrMap[attrType][key].attrs).forEach((attr) => {
                if(tempAttrMap[attrType][key].attrs[attr].enabled) {
                    newAttr[attr] = {...tempAttrMap[attrType][key].attrs[attr]};
                }
            });
            tempAttrMap[attrType][key].attrs = newAttr;
        });

        return tempAttrMap;
    }
    handleTypeClik = (type) => {
        const { attrMap } = this.state;
        let {attrType} = this.props;
        let activeTypes = Object.keys(attrMap[attrType]).filter(el => !!(attrMap[attrType][el].enabled && attrMap[attrType][el].selected));//当前选中了哪些项
        // 至少选一项
        if (activeTypes.length === 1 && activeTypes[0] === type) {
            return;
        }
        attrMap[attrType][type]['selected'] = !attrMap[attrType][type]['selected'];
        if (['body', 'nonmotor'].includes(type) && attrMap[attrType][type]['selected']) {
            attrMap[attrType]['face']['selected'] = true;
        }
        this.setState({ attrMap }, () => {
            this.props.reAlignPop();
        });
    }
    getAttrs = (type) => {
        const { attrMap } = this.state;
        const { $t, attrTrans, attrType, viewMode } = this.props;
        let selectedCount = _.filter(attrMap[attrType][type]['attrs'], { 'enabled': true, 'selected': true, 'visible': true }).length;

        return _.map(attrMap[attrType][type]['attrs'], (val, key) => {
            if (viewMode === 'portrait') {
                if (key === 'HatStatus' && this.state.detcetMode) {
                    key = 'Helmet';
                }
            }
            const keyStr = $t(attrTrans[key]);
            if (!val.visible) return;
            return (
                <Tooltip title={keyStr} mouseLeaveDelay={0} key={type + key}>
                    <Button
                        className={val.enabled && val.selected ? 'selected' : null}
                        disabled={selectedCount >= 7 && !val.selected ? true : false}
                        onClick={e => this.handleAttrClik(type, key)}
                    >{keyStr}</Button>
                </Tooltip>
            );
        });

    }
    handleAttrClik = (type, key) => {
        const { attrMap } = this.state;
        let {attrType, viewMode} = this.props;
        if (viewMode === 'portrait') {
            if (key === 'Helmet') {
                key = 'HatStatus';
            }
        }
        //超过七条不允许再勾选
        let moreThan7 = _.filter(attrMap[attrType][type]['attrs'], { 'enabled': true, 'selected': true, 'visible': true }).length >= 7;
        attrMap[attrType][type]['attrs'][key].selected = moreThan7 ? false : !attrMap[attrType][type]['attrs'][key].selected;
        this.setState({ attrMap });

    }
    onSubmit = () => {
        const { updateData, updateAttrMap, videoRef, attrType } = this.props;
        const { attrMap } = this.state;
        const typesChanged = _.some(this.props.attrMap[attrType], (val, key) => {
            return val.selected !== attrMap[attrType][key].selected || val.enabled !== attrMap[attrType][key].enabled;
        });
        const newAttrMap = _.merge(this.props.attrMap, attrMap);
        setItem('attrMaps', JSON.stringify(newAttrMap), 30);
        //属性大类有修改会清空画面中的数据
        if (typesChanged) {
            updateAttrMap({ ...newAttrMap }, attrType).then(() => {
                //改了配置之后布局可能变，所以这里需要调一下，让控件调整位置
                plugin.cover(videoRef);
            });
        } else {
            updateData({
                attrMap: { ...newAttrMap }
            });
            //恢复视频
            // plugin.cover(videoRef);
        }
        updateData({ attrModalVisible: false });
    }
    //从内部关闭弹窗时的逻辑
    onCancel = () => {
        const { updateData, attrMap, videoRef } = this.props;
        updateData({
            attrModalVisible: false
        });
        //恢复视频
        // plugin.cover(videoRef);
    }
    render () {
        const { $t, attrTrans, updateData, viewMode, attrType } = this.props;
        const { attrMap, detcetMode, options } = this.state;
        const supportTypes = Object.keys(attrMap[attrType]).filter(el => !!(attrMap[attrType][el]['enabled']));
        const selectTypes = Object.keys(attrMap[attrType]).filter(el => !!(attrMap[attrType][el]['selected']));
        return (
            <div className='_AttrSetting_'>
                {viewMode === 'portrait' &&
                    <div className='mode'>
                        <LabelSelect
                            label = {$t('ivs.DetectMode')}
                            disabled = {true}
                            value= {detcetMode}
                            options = {options}
                        />
                    </div>
                }
                {supportTypes.length >= 2 ? (
                    <>
                        <div setting_title=''>
                            {$t('ivs.AIPropsSetting')}(
                            <span seleted_num=''>{_.filter(attrMap[attrType], { 'enabled': true, 'selected': true }).length}</span>
                            /{viewMode === 'portrait' ? 2 : 4})
                        </div>
                        <div types=''>
                            {attrMap[attrType].face.enabled ? (
                                <div
                                    type_cell=''
                                    className={attrMap[attrType].face.selected ? 'selected' : ''}
                                    onClick={() => this.handleTypeClik('face')}
                                >
                                    <Icon type='face' />
                                    <br />
                                    <Tooltip title={$t('ivs.Face')}><span>{$t('ivs.Face')}</span></Tooltip>
                                </div>
                            ) : null}
                            {!viewMode.includes('behavior') && attrMap[attrType].body.enabled ? (
                                <div
                                    type_cell=''
                                    className={attrMap[attrType].body.selected ? 'selected' : ''}
                                    onClick={() => this.handleTypeClik('body')}
                                >
                                    <Icon type='body' />
                                    <br />
                                    <Tooltip title={$t('com.HumanTrait')}><span>{$t('com.HumanTrait')}</span></Tooltip>
                                </div>
                            ) : null}
                            {!modeArr.includes(viewMode) && attrMap[attrType].nonmotor.enabled ? (
                                <div
                                    type_cell=''
                                    className={attrMap[attrType].nonmotor.selected ? 'selected' : ''}
                                    onClick={() => this.handleTypeClik('nonmotor')}
                                >
                                    <Icon type='nonmotor' />
                                    <br />
                                    <Tooltip title={$t('ivs.NoMotorVehicle')}><span>{$t('ivs.NoMotorVehicle')}</span></Tooltip>
                                </div>
                            ) : null}
                            {!modeArr.includes(viewMode) && attrMap[attrType].car.enabled ? (
                                <div
                                    type_cell=''
                                    className={attrMap[attrType].car.selected ? 'selected' : ''}
                                    onClick={() => this.handleTypeClik('car')}
                                >
                                    <Icon type='car' />
                                    <br />
                                    <Tooltip title={$t('ivs.MotorVehicle')}><span>{$t('ivs.MotorVehicle')}</span></Tooltip>
                                </div>
                            ) : null}
                            {viewMode.includes('behavior') && attrMap[attrType].classroom.enabled ? (
                                <div
                                    type_cell=''
                                    className={attrMap[attrType].classroom.selected ? 'selected' : ''}
                                    onClick={() => this.handleTypeClik('classroom')}
                                >
                                    <Icon type='classBehavior' />
                                    <br />
                                    <Tooltip title={$t('ivs.Action')}><span>{$t('ivs.Action')}</span></Tooltip>
                                </div>
                            ) : null}

                        </div>
                    </>
                ) : null}
                {supportTypes.includes('face') && selectTypes.includes('face') ? (
                    <div type='face'>
                        <div setting_title=''>
                            {$t('ivs.FacePropsDisplayed')}(
                            <span seleted_num=''>{_.filter(this.state.attrMap[attrType].face.attrs, { 'enabled': true, 'selected': true, 'visible': true }).length}</span>
                        /{viewMode === 'portrait' && detcetMode === 1 ? 2 : 6})
                        </div>
                        <div setting_content=''>
                            {this.getAttrs('face')}
                        </div>
                    </div>
                ) : null}
                {supportTypes.includes('body') && selectTypes.includes('body') ? (
                    <div type='body'>
                        <div setting_title=''>
                            {$t('ivs.BodyPropsDisplayed')}(
                            <span seleted_num=''>{_.filter(this.state.attrMap[attrType].body.attrs, { 'enabled': true, 'selected': true,'visible': true }).length}</span>
                                /{viewMode === 'portrait' && detcetMode === 1 ? 4 : 7})
                        </div>
                        <div setting_content=''>
                            {this.getAttrs('body')}
                        </div>
                    </div>
                ) : null}
                {supportTypes.includes('nonmotor') && selectTypes.includes('nonmotor') ? (
                    <div type='nonmotor'>
                        <div setting_title=''>
                            {$t('ivs.NonmotorPropsDisplayed')}(
                            <span seleted_num=''>{_.filter(attrMap[attrType].nonmotor.attrs, { 'enabled': true, 'selected': true,'visible': true }).length}</span>
                                                    /6)
                        </div>
                        <div setting_content=''>
                            {this.getAttrs('nonmotor')}
                        </div>
                    </div>
                ) : null}
                {supportTypes.includes('car') && selectTypes.includes('car') ? (
                    <div type='car'>
                        <div setting_title=''>
                            {$t('ivs.MotorPropsDisplayed')}(
                            <span seleted_num=''>
                                {_.filter(attrMap[attrType].car.attrs, { 'enabled': true, 'selected': true, 'visible': true }).length}
                            </span>
                                                    /{Math.min(Object.keys(attrMap[attrType].car.attrs).length, 7)})
                        </div>
                        <div setting_content=''>
                            {this.getAttrs('car')}
                        </div>
                    </div>
                ) : null}
                {supportTypes.includes('classroom') && selectTypes.includes('classroom') ? (
                    <div type='classBehavior'>
                        <div setting_title=''>
                            {$t('ivs.FacePropsDisplayed')}(
                            <span seleted_num=''>{_.filter(attrMap[attrType].classroom.attrs, { 'enabled': true, 'selected': true, 'visible': true }).length}</span>
                                                    /5)
                        </div>
                        <div setting_content=''>
                            {this.getAttrs('classroom')}
                        </div>
                    </div>
                ) : null}
                <div foot=''>
                    <Button type='primary' onClick={this.onSubmit}>{$t('com.Apply')}</Button>
                    <Button onClick={this.onCancel}>{$t('com.Cancel')}</Button>
                </div>
            </div>
        );
    }
}
export default LangConsumer(AttrSetting);